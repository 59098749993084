<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      no-filters
      :filter-names="filtersName"
      search-label="search"
      @filterOption="onFiltersChange"
    >
      <template v-slot:productTiers="{ item }">
        <div>
          {{ item.productTiers.map((r) => r.product_tier_name).join(", ") }}
        </div>
      </template>
      <template v-slot:is_active="{ item }">
        <span v-if="item.is_active == '1'">
          {{ $t("active") }}
        </span>
        <span v-else>
          {{ $t("inactive") }}
        </span>
      </template>
      <template v-slot:actions="{ item }">
        <v-icon
          v-if="$admin.can('tenants.edit')"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="$admin.can('tenants.delete')"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <!-- add Filters -->
      <!-- <template #addFilters> </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div>
          <create-tenant
            v-if="$admin.can('tenants.create')"
            :filter-options="options"
          />
          <edit-tenant
            v-if="$admin.can('tenants.edit') && editModal"
            :is-visible-dialog="editModal"
            :toggle-edit="toggleEditModal"
            :items="selectedRow"
            :options="options"
          />
          <confirmation-modal
            v-if="$admin.can('tenants.delete')"
            :is-visible="deleteModal"
            :toggle="toggleDeleteModal"
            :row="selectedRow"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    CreateTenant: () => import("./dialogs/CreateTenant.vue"),
    EditTenant: () => import("./dialogs/EditTenant.vue"),
    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},
      headers: [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t("name"),
          align: "left",
          sortable: false,
          value: "name",
          width: "15%",
        },
        {
          text: this.$t("email"),
          align: "left",
          sortable: false,
          value: "email",
        },
        {
          text: this.$t("contact"),
          align: "left",
          sortable: false,
          value: "contact_no",
          width: "10%",
        },
        {
          text: this.$t("address"),
          align: "left",
          sortable: false,
          value: "address",
        },
        {
          text: this.$t("no_of_allowed_users"),
          align: "left",
          sortable: true,
          value: "no_of_allowed_users",
        },
        {
          text: this.$t("product_with_tier"),
          align: "left",
          sortable: false,
          value: "productTiers",
        },
        {
          text: this.$t("status"),
          align: "left",
          sortable: false,
          value: "is_active",
        },

        ...(this.$admin.hasAccessTo("tenants.delete") ||
          this.$admin.hasAccessTo("tenants.edit")
          ? [
            {
              text: this.$t("actions"),
              align: "left",
              sortable: false,
              value: "actions",
            },
          ]
          : []),
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "tenantsManagement/getIsLoading",
      meta: "tenantsManagement/getMeta",
      list: "tenantsManagement/getTenantsList",
      //
      productWithTiers: "products/getProductWithTiers",
    }),
    filtersName() {
      return ["search", "product_tier"];
    },
    _headers() {
      // computed headers for showing columns to role based
      return this.headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  async mounted() {
    await this.$store.dispatch("oemBusiness/list"); // call for creat and edit
  },
  beforeDestroy() {
    const params = {
      name: "tenants_management",
      filters: {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        search: this.options.search || undefined,
        product_id: this.options.product_id || undefined,
        product_tier_id: this.options.product_tier_id || undefined,
      },
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      await this.$store
        .dispatch("tenantsManagement/list", this.options)
        .then(() => {
          if (this.options.page > (this.meta?.lastPage || 1) && this.list.length === 0) {
            this.options.page = this.meta.lastPage || 1; // Go to the last valid page
          }
        });
    },

    // Confirmation of Delete Row
    async deleteSelectedRow(row) {
      try {
        await this.$store.dispatch("tenantsManagement/destroy", row);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        if (this.list.length === 0 && this.meta.lastPage == this.options.page) {
          this.options.page -= 1; // Move to the previous page
        }
        this.toggleDeleteModal("close");
        this.fetchData();
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>